<template>
    <modal name="tools-filter" width="520px" height="auto" @before-open="beforeOpen" classes="tw-rounded-md">
        <div class="tw-flex tw-flex-col tw-justify-between tw-min-h-full tw-h-full">
            <header class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-0 tw-border-solid tw-border-gray-200 tw-bg-gray-50">
                <div class="tw-flex tw-flex-col">
                    <span class="tw-font-semibold tw-text-black tw-text-lg tw-tracking-wide">Search Queries Filters</span>
                    <span class="tw-text-gray-400 tw-font-medium tw-text-sm tw-tracking-wide">Start filtering your searched queries</span>
                </div>
                <svg @click="onCloseFilter" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x tw-rounded-full tw-p-2 tw-bg-gray-200 tw-text-gray-600 tw-cursor-pointer hover:tw-text-brand-hover">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </header>
            <div class="tw-container tw-flex-grow tw-overflow-y-auto scroll-bar tw-px-4 tw-py-6">
                <div class="tw-flex tw-flex-col tw-mb-4">
                    <span class="tw-text-gray-500 tw-tracking-wide tw-mb-2">Monitoring</span>
                    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
                        <div class="tw-flex tw-items-center">
                            <input name="monitoring" class="tw-cursor-pointer" id="monitoringall" type="radio" value="all" v-model="filters.monitoring" />
                            <label for="monitoringall" class="tw-cursor-pointer tw-ml-1">All</label>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <input name="monitoring" class="tw-cursor-pointer" id="monitoring" type="radio" value="monitoring" v-model="filters.monitoring" />
                            <label for="monitoring" class="tw-cursor-pointer tw-ml-1">Monitoring</label>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <input name="monitoring" class="tw-cursor-pointer" id="monitoringnotall" type="radio" value="not_monitoring" v-model="filters.monitoring" />
                            <label for="monitoringnotall" class="tw-cursor-pointer tw-ml-1">Not Monitoring</label>
                        </div>
                    </div>
                </div>
                <div class="tw-flex tw-flex-col">
                    <span class="tw-text-gray-500 tw-tracking-wide tw-mb-2">Saved</span>
                    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
                        <div class="tw-flex tw-items-center">
                            <input name="saved_queries" class="tw-cursor-pointer" id="savedAll" type="radio" value="all" v-model="filters.saved" />
                            <label for="savedAll" class="tw-cursor-pointer tw-ml-1">All</label>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <input name="saved_queries" class="tw-cursor-pointer" id="saved" type="radio" value="saved" v-model="filters.saved" />
                            <label for="saved" class="tw-cursor-pointer tw-ml-1">Saved</label>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <input name="saved_queries" class="tw-cursor-pointer" id="savednotall" type="radio" value="not_saved" v-model="filters.saved" />
                            <label for="savednotall" class="tw-cursor-pointer tw-ml-1">Not Saved</label>
                        </div>
                    </div>
                </div>

                <!-- <div class="tw-mt-3">
                    <div class="tw-text-lg tw-text-gray-600 tw-font-semibold tw-mb-3 tw-tracking-wide">Results</div>
                    <component :is="componentName" :tools="tools" :filter="filters.tools[componentName]" @onFilterChange="onFilterChange" />
                </div> -->
            </div>
            <footer class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-t tw-border-0 tw-border-solid tw-border-gray-200 tw-bg-gray-50">
                <button @click="onResetFilter()" class="tw-border-none tw-py-2 tw-px-6 tw-bg-gray-200 tw-cursor-pointer tw-rounded tw-text-sm tw-h-full tw-flex tw-items-center tw-text-brand hover:tw-bg-brand hover:tw-text-white">
                    Reset
                </button>
                <button :disabled="!filtersChanged" :class="!filtersChanged ? 'tw-bg-gray-500 tw-cursor-not-allowed' : 'tw-bg-brand tw-cursor-pointer hover:tw-bg-brand-hover'" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-text-white" @click="onApplyFilter()">
                    <font-awesome-icon icon="filter" class="tw-w-4 tw-mr-2" />
                    Apply Filter
                </button>
            </footer>
        </div>
    </modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {isEqual} from "lodash";

// const NewsTool = () => import("./tools/news-tool.vue");
// const AdverseMediaTool = () => import("./tools/adverse-media-tool.vue");
// const NeoToggleSwitch = () => import("@/components/toggle-switch");

export default {
    name: "Tools-Filter",
    data() {
        return {
            componentName: "",
            filters: {
                monitoring: "all",
                saved: "all",
                // tools: {
                //     "news-tool": { saved: "all" },
                //     "adverse-media-tool": { saved: "all" },
                // },
            },
            lastAppliedFilter: null,
        };
    },
    props: {
        tools: {
            type: Object,
            default: () => {},
        },
    },
    // components: {
    //     AdverseMediaTool,
    //     NeoToggleSwitch,
    //     NewsTool,
    // },
    methods: {
        ...mapActions(["setToolsFilterData", "getToolsStatus"]),
        onCloseFilter() {
            // let filtersCount = 0;
            // for (const filter in this.lastAppliedFilter) {
            //     if (filter !== "tools" && this.lastAppliedFilter[filter] !== "all") filtersCount += 1;
            // else if (filter === "tools") {
            //     switch (this.componentName) {
            //         case "adverse-media-tool":
            //             if (this.lastAppliedFilter.tools[this.componentName].saved !== "all") filtersCount += 1;
            //             if (this.lastAppliedFilter.tools[this.componentName].searchQuery) filtersCount += 1;
            //             if (this.lastAppliedFilter.tools[this.componentName].sourceType?.length) filtersCount += 1;
            //             if (this.lastAppliedFilter.tools[this.componentName].filterEntityType?.length) filtersCount += 1;
            //             if (this.lastAppliedFilter.tools[this.componentName].filterTypes?.length) filtersCount += 1;
            //             if (this.lastAppliedFilter.tools[this.componentName].filterJurisdiction?.length) filtersCount += 1;
            //             break;
            //         case "news-tool":
            //             if (this.lastAppliedFilter.tools[this.componentName].saved !== "all") filtersCount += 1;
            //             break;
            //         default:
            //             break;
            //     }
            // }
            // }
            // this.$emit("updateFilters", filtersCount);
            this.$modal.hide("tools-filter");
        },
        beforeOpen({params}) {
            this.componentName = params.currentToolSelected;
            if (this.getToolsFilterData) {
                const {monitoring, saved} = this.getToolsFilterData;
                if (monitoring === "all") {
                    this.filters.monitoring = "all";
                } else {
                    this.filters.monitoring = monitoring ? "monitoring" : "not_monitoring";
                }
                if (saved === "all") {
                    this.filters.saved = "all";
                } else {
                    this.filters.saved = saved ? "saved" : "not_saved";
                }
            } else {
                this.filters = {
                    monitoring: "all",
                    saved: "all",
                    // tools: {
                    //     "news-tool": { saved: "all" },
                    //     "adverse-media-tool": { saved: "all" },
                    // },
                };
            }
            // switch (this.componentName) {
            //     case "adverse-media-tool":
            //         this.tools.searchQuery = this.lastAppliedFilter?.tools[this.componentName]?.searchQuery ?? "";
            //         this.tools.sourceType = this.lastAppliedFilter?.tools[this.componentName]?.sourceType ?? [];
            //         this.tools.filterEntityType = this.lastAppliedFilter?.tools[this.componentName]?.filterEntityType ?? [];
            //         this.tools.filterTypes = this.lastAppliedFilter?.tools[this.componentName]?.filterTypes ?? [];
            //         this.tools.filterJurisdiction = this.lastAppliedFilter?.tools[this.componentName]?.filterJurisdiction ?? [];
            //         break;
            //     default:
            //         break;
            // }
        },
        async onApplyFilter() {
            if (this.filtersChanged) {
                this.setToolsFilterData(this.filters);
                await this.getToolsStatus();
                if (!this.tools.isAllRunQueries) {
                    const queryExist = this.searchedQueries.some((query) => query._id === this.tools.request_id);
                    if (!queryExist) {
                        this.tools.resetFilter();
                    }
                }
                this.onCloseFilter();
            }
            // this.lastAppliedFilter = cloneDeep(this.filters);
            // switch (this.componentName) {
            //     case "adverse-media-tool":
            //         this.tools.filterData();
            //         this.tools.savedFilter = this.filters.tools[this.componentName].saved;
            //         this.tools.search();
            //         this.lastAppliedFilter.tools[this.componentName] = {
            //             searchQuery: this.tools.searchQuery,
            //             sourceType: this.tools.sourceType,
            //             filterEntityType: this.tools.filterEntityType,
            //             filterTypes: this.tools.filterTypes,
            //             filterJurisdiction: this.tools.filterJurisdiction,
            //             saved: this.filters.tools[this.componentName].saved,
            //         };
            //         break;
            //     case "news-tool":
            //         this.tools.savedFilter = this.filters.tools[this.componentName].saved;
            //         this.tools.search();
            //         break;

            //     default:
            //         break;
            // }
        },
        async onResetFilter() {
            this.filters = {
                monitoring: "all",
                saved: "all",
                // tools: {
                //     "news-tool": { saved: "all" },
                //     "adverse-media-tool": { saved: "all" },
                // },
            };
            await this.onApplyFilter();
            // switch (this.componentName) {
            //     case "adverse-media-tool":
            //         this.tools.clearFilters();
            //         break;

            //     default:
            //         break;
            // }
        },
        onFilterChange(event) {
            this.filters.tools[this.componentName].saved = event;
        },
    },
    computed: {
        ...mapGetters(["getToolsFilterData", "searchedQueries"]),
        filtersChanged() {
            let {monitoring, saved} = this.getToolsFilterData;
            if (monitoring === "all") {
                monitoring = "all";
            } else {
                monitoring = monitoring ? "monitoring" : "not_monitoring";
            }

            if (saved === "all") {
                saved = "all";
            } else {
                saved = saved ? "saved" : "not_saved";
            }
            let appliedFilters = {monitoring, saved};
            return !isEqual(appliedFilters, this.filters);
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep {
    .multiselect__option,
    .multiselect__tags,
    .multiselect__tags-wrap {
        span {
            text-transform: capitalize;
        }
    }
}
</style>
